<template>
  <span
    class="gap-[10px] ui-font-semibold ui-text-white ui-justify-center ui-text-center ui-rounded-pill ui-flex ui-min-w-md ui-h-md ui-flex-col ui-px-2xs ui-items-center"
    :class="variantClasses[variant]"
  >
    {{ text }}
  </span>
</template>

<script lang="ts" setup>
import type { BadgeProps } from "./types";

defineProps<BadgeProps>();

const variantClasses = {
  ["primary"]: "ui-bg-mkm-blue-default",
  ["secondary"]: "ui-bg-charcoal-light",
  ["error"]: "ui-bg-red-default",
  ["warning"]: "ui-bg-orange-default",
  ["success"]: "ui-bg-green-dark",
};
</script>
