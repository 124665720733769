<template>
  <div v-show="selectedTitle == title">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { inject } from "vue";
import { TabProps } from "./types";

defineProps<TabProps>();
const selectedTitle = inject("selectedTitle");
</script>
