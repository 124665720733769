<template>
  <div :class="['ui-flex', vertical ? 'ui-flex-row' : 'ui-flex-col']">
    <ul
      :class="[
        'ui-flex',
        alignClass[align],
        vertical ? 'ui-flex-col' : 'ui-flex-row',
        borderBottom ? 'border-b-sm border-grey-light' : '',
      ]"
      v-if="TabsData?.length > 0"
    >
      <li
        v-for="(title, index) in TabsData.map((tab) => tab?.title)"
        :data-test-id="TabsData[index]['data-test-id']"
        :key="index"
        @click="selectedTitle = title"
        :class="[
          'ui-basis-1/2 sm:ui-basis-auto ui-p-xs  ui-transition ui-duration-200 ui-font-semibold  ui-flex ui-gap-xs ui-items-center ui-justify-center hover:ui-text-mkm-blue-light',
          selectedTitle === title
            ? 'ui-border-mkm-blue-light ui-text-mkm-blue-light'
            : 'ui-border-grey-light ui-text-charcoal-default',
          vertical
            ? 'ui-border-l-lg md:ui-px-md'
            : 'ui-border-b-lg md:ui-px-lg',
          TabsData[index]?.disabled
            ? 'ui-pointer-events-none ui-opacity-50'
            : 'ui-cursor-pointer',
        ]"
      >
        <Icon
          v-if="TabsData[index].icon"
          :name="TabsData[index].icon || 'add-star'"
          :size="16"
        />
        {{ title }}
        <Badge
          :variant="selectedTitle === title ? 'primary' : 'secondary'"
          v-if="TabsData[index].count"
          :text="TabsData[index].count"
        />
      </li>
    </ul>
    <div :class="[slotClass, 'ui-mt-xs']">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, provide, useSlots, watch, computed } from "vue";
import Badge from "../Badge/Badge.vue";
import Icon from "../Icon/Icon.vue";
import { TabProps, TabsProps } from "./types";

const slots = useSlots();

const props = defineProps<TabsProps>();
const emit = defineEmits<{
  (event: "update:tab", value: string): void;
}>();

const TabsData = computed(() => {
  return slots.default
    ? (slots.default().map((tab) => tab.props) as TabProps[])
    : [];
});

const selectedTitle = ref(
  props.activeTab
    ? TabsData.value[props.activeTab]?.title
    : TabsData.value[0]?.title,
);

provide("selectedTitle", selectedTitle);

const alignClass = {
  ["left"]: "ui-justify-start",
  ["center"]: "ui-justify-center",
  ["right"]: "ui-justify-end",
};

watch(selectedTitle, () => {
  emit("update:tab", selectedTitle.value);
});
</script>
